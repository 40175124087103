import React from "react";
import PageMeta from "../components/PageMeta";
import ContactPage from "../components/ContactPage";

export default function AccreditationPage() {
  return (
    <>
      <PageMeta title="QCL - Accreditation" path="accreditation" />
      <div className="col3 t-col2">
        <h1 className="top">Accreditation</h1>
        <p className="lead">
          QCL has been a full member of the United Kingdom Home Care Association
          (UKHCA) since 1997.
        </p>
        <p>
          QCL is registered and regularly inspected by the Care Quality
          Commission (CQC).
        </p>
        <p>
          QCL is regularly inspected by “Contract Compliance” officers from
          various London Boroughs such as Hounslow, Hillingdon and Ealing.
        </p>
        <p>
          QCL’s policies and procedures are developed in line with all current
          Government and local legislation.
        </p>
        <p>
          As part of our staff development programme, all carers are engaged in
          individual training “pathways” – they are accredited to or are working
          towards NVQ Level 2.
        </p>
        <p>
          Senior staff are accredited to or are working towards NVQ Level 3.
        </p>
        <p>
          QCL’s policy of continuous training and development ensures that
          carers are trained and certified in the following subjects:
        </p>
        <ul>
          <li>Moving &amp; Handling</li>
          <li>Moving &amp; Handling Refresher 2014</li>
          <li>Medication (Level 2)</li>
          <li>Medication Refresher 2014</li>
          <li>Food Hygiene &amp; Safety</li>
          <li>Health &amp; Safety</li>
          <li>Infection Control</li>
          <li>Safeguarding Adults</li>
          <li>First Aid</li>
          <li>Dementia</li>
          <li>Intermediate Apprenticeship in Health and Social Care Level 2</li>
          <li>Advanced Apprenticeship in Health and Social Care Level 3</li>
        </ul>
      </div>
      <div className="col3 t-col1">
        <ContactPage />
      </div>
    </>
  );
}
